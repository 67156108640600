import React from 'react';
import SEO from '../components/layout/seo';
import CustomerView from '../components/customer/customer-view';
import { useAuthUserCheckOrError } from '../libs/handleHttpError';
import MemberLayout from '../components/layout/member-layout';

const CustomerPage = () => {
  useAuthUserCheckOrError();

  return (
    <MemberLayout>
      <SEO title="Account Profile" />
      <CustomerView />
    </MemberLayout>
  );
};

export default CustomerPage;
